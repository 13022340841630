<template>
  <v-card
    flat
    class="mt-3 pa-3 pt-10 pb-10"
    style="margin: auto"
    max-width="750px"
  >
    <div class="text-center">
      <v-icon size="7rem" color="success">mdi-checkbox-marked-circle</v-icon>
      <h3 class="mt-2">Registration Completed!</h3>
      <br />
      Application No. : {{ regNo }}
    </div>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    regNo: "",
  }),
  mounted() {
    if(this.$store.state.newAppId == ''){
      this.$router.push("/" + this.$store.state.eventUrl + '/login')
    }
    this.$store.commit("setPageTitle", "Thank you");
    this.regNo = this.$store.state.newAppId;
  },
};
</script>

<style>
</style>